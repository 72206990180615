<template>
  <page-container title="一键生成履约报告" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 24px; padding-bottom: 24px;">
      <div class="box-container-inner">
        <a-form-model ref="modalForm" :model="formDatas" :rules="formRules" :label-col="{span:7}" :wrapper-col="{span:14}">
          <a-form-model-item :wrapper-col="{ span: 22, offset: 0 }" style="width: 100%; margin-bottom: 20px; overflow: hidden;">
            <a-button size="large" type="primary" @click="exportData" style="float: right;">生成履约报告</a-button>
          </a-form-model-item>
          <a-form-model-item label="项目" prop="userdepid">
            <a-cascader :show-search="{ filter }" :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="deptOptions" change-on-select v-model="deptCascaderSelected" :load-data="deptOnLoadChildren" placeholder="请选择项目" :allow-clear="false"></a-cascader>
          </a-form-model-item>
          <a-form-model-item label="年份" prop="year">
            <a-select v-model="formDatas.year">
              <a-select-option v-for="(item, index) in yearOptions" :key="index" :value="item">{{item+'年'}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="半年" prop="halfyear">
            <a-select v-model="formDatas.halfyear">
              <a-select-option value="1">上半年</a-select-option>
              <a-select-option value="2">下半年</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="季度" prop="quarter">
            <a-select v-model="formDatas.quarter">
              <template v-if="formDatas.halfyear == '1'">
                <a-select-option v-for="i in 2" :key="i" :value="i+''">{{'第'+i+'季度'}}</a-select-option>
              </template>
              <template v-else-if="formDatas.halfyear == '2'">
                <a-select-option v-for="i in 2" :key="i" :value="i+2+''">{{'第'+(i+2)+'季度'}}</a-select-option>
              </template>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="月份" prop="month">
            <a-select v-model="formDatas.month">
              <template v-if="formDatas.quarter=='1'">
                <a-select-option v-for="i in 3" :key="i" :value="i+''">{{i+'月'}}</a-select-option>
              </template>
              <template v-else-if="formDatas.quarter=='2'">
                <a-select-option v-for="i in 3" :key="i" :value="i+3+''">{{i+3+'月'}}</a-select-option>
              </template>
              <template v-else-if="formDatas.quarter=='3'">
                <a-select-option v-for="i in 3" :key="i" :value="i+6+''">{{i+6+'月'}}</a-select-option>
              </template>
              <template v-else-if="formDatas.quarter=='4'">
                <a-select-option v-for="i in 3" :key="i" :value="i+9+''">{{i+9+'月'}}</a-select-option>
              </template>
            </a-select>
          </a-form-model-item>
        </a-form-model>
      </div>
    </div>
  </page-container>
</template>
<script>

import deptselect from '@/mixins/deptselect'
import {exportPerformanceReport} from 'A/performance.js'
import moment from 'moment'
import {mapState} from "vuex";

export default {
  mixins: [deptselect],
  data() {
    return {
      breadcrumb: [
        {
          name: '数据分析与报告',
          path: ''
        },
        {
          name: '一键生成履约报告',
          path: ''
        },
      ],
      formDatas: {
        userdepid: '',
        year: '',
        halfyear: '',
        quarter: '',
        month: '',
        halfmonth:'',
        week:'',
      },
      areaOptions: [],
      areaCascaderSelected: [],
      yearOptions: [],
      formRules: {
        userdepid: [{required: true, message: '请选择单位'}],
        year: [{required: true, message: '请选择年份'}],
      },
      weekMap:[],
      initDeptType: "1",
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
  },
  watch: {
    deptCascaderSelected(val) {
      if(val && val.length) {
        this.formDatas.userdepid = val[val.length-1];
        this.$refs.modalForm.clearValidate('userdepid')
      }
    },
    'formDatas.year'(val) {
      this.formDatas.halfyear = '';
    },
    'formDatas.halfyear'(val) {
      this.formDatas.quarter = '';
    },
    'formDatas.quarter'(val) {
      this.formDatas.month = '';
    },
    'formDatas.month'(val) {
      this.formDatas.week = '';
      this.formDatas.halfmonth = '';
      this.weekMap=[];
      this.getMonthWeek(val)
    },
  },
  created() {
    this.init();
    this.formDatas.userdepid=this.userInfo.userdepid
  },
  methods: {
    filter(inputValue, path) {
      return path.some(option => option.userdepname.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    },
    areaFilter(inputValue, path) {
      return path.some(option => option.areaname.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    },
    init() {
      this.getYearOptions();
    },
    getYearOptions() {
      this.yearOptions = [];
      let currentYear = moment().year();
      for(let i=4; i>-1; i--) {
        this.yearOptions.push(currentYear-i+'');
      }
    },
    getWeekOptions(datetime){
      let yearNum = moment(datetime).format('YYYY')
      let monthNum = moment(datetime).format('MM')
      let dayNum = moment(datetime).format('DD')
      // let getDate = this.getMonthWeek(yearNum, monthNum, dayNum)
      // let dateStr = getDate.getYear + '年' + getDate.getMonth + '月第' + getDate.getWeek + '周'
      // console.log(dateStr)
      let dayList = [31,59,90,120,151,181,212,243,273,304,334,365]
      let date = new Date(yearNum, parseInt(monthNum) - 1, dayNum)
      //年份
      let year =date.getFullYear()
      let yearnum = 365
      let yeartype = '平年'
      //星期
      let week = date.getDay()
      //日
      let day = date.getDate()
      //判断闰年还是平年
      if((year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0)){
        yeartype = '闰年'
        yearnum=366
        for(let i=1;i<dayList.length;i++){
          dayList[i]+=1
        }
      } else {
        yeartype = '平年'
        yearnum=365
      }
      if (week === 0) {
        week = 7
        dayList[0]=dayList[0]-(7-week+1)
        yearnum=yearnum-(7-week+1)
      }else if(week!==1){
        dayList[0]=dayList[0]-(7-week+1)
        yearnum=yearnum-(7-week+1)
      }
      for(let i=1;i<dayList.length;i++){
        dayList[i]-=7-week+1
      }
      return {
        yeartype: yeartype,
        yearnum: yearnum,
        dayList: dayList
      }
    },
    getMonthWeek (month) {
      let config = this.getWeekOptions(this.formDatas.year+"-1-1")
      let dayList = config.dayList
      let yearnum = config.yearnum
      let yeartype = config.yeartype
      let week = Math.floor((yearnum-dayList[month-2])/7)

      let yearNum1 = moment(this.formDatas.year+"-"+month+"-1").format('YYYY')
      let monthNum1 = moment(this.formDatas.year+"-"+month+"-1").format('MM')
      let dayNum1 = moment(this.formDatas.year+"-"+month+"-1").format('DD')
      let date1 = new Date(yearNum1, parseInt(monthNum1) - 1, dayNum1)
      //星期
      let week1 = date1.getDay()
      //日
      let day1 = date1.getDate()
      if(month==1){
        week=51
      }
      if(month==2) {
        if(week1==1) {
          this.weekMap.push({key: 53 - week, value: (53 - week)+"周("+"2-1~2-7"+")"})
          this.weekMap.push({key: 53 - week + 1, value: (53 - week+1)+"周("+"2-8~2-14"+")"})
          this.weekMap.push({key: 53 - week + 2, value: (53 - week+2)+"周("+"2-15~2-21"+")"})
          this.weekMap.push({key: 53 - week + 3, value: (53 - week+3)+"周("+"2-22~2-28"+")"})
          if(yeartype=='闰年'){
            this.weekMap.push({key: 53 - week + 4, value: (53 - week+4)+"周("+"2-29~3-6"+")"})
          }
        }else if(week1==0){
          this.weekMap.push({key:53-week,value:(53 - week)+"周("+"2-2~2-8"+")"})
          this.weekMap.push({key:53-week+1,value:(53 - week+1)+"周("+"2-9~2-15"+")"})
          this.weekMap.push({key:53-week+2,value:(53 - week+2)+"周("+"2-2-16~22"+")"})
          if(yeartype=='闰年'){
            this.weekMap.push({key:53-week+3,value:(53 - week+3)+"周("+"2-23~2-29"+")"})
          }else{
            this.weekMap.push({key:53-week+3,value:(53 - week+3)+"周("+"2-23~3-1"+")"})
          }
        }else{
          this.weekMap.push({key: 53 - week, value: (53 - week)+"周("+"2-"+(9 - week1) + "~" + "2-"+(9 - week1 + 6)+")"})
          this.weekMap.push({key: 53 - week + 1, value: (53 - week + 1)+"周("+"2-"+(9 - week1 + 7) + "~" + "2-"+(9 - week1 + 13)+")"})
          this.weekMap.push({key: 53 - week + 2, value: (53 - week + 2)+"周("+"2-"+(9 - week1 + 14) + "~" + "2-"+(9 - week1 + 20)+")"})
          if(yeartype=='闰年'){
            this.weekMap.push({key: 53 - week + 3, value: (53 - week + 3)+"周("+"2-"+(9 - week1 + 21) + "~" +"3-"+ (9 - week1 + 27 - 29)+")"})
          }else{
            this.weekMap.push({key: 53 - week + 3, value: (53 - week + 3)+"周("+"2-"+(9 - week1 + 21) + "~" + "3-"+(9 - week1 + 27 - 28)+")"})
          }
        }
      }else if(month==1||month==3||month==5||month==7||month==8||month==10||month==12){
        if(week1==1){
          this.weekMap.push({key:53-week,value:(53 - week)+"周("+month+"-1~"+month+"-7"+")"})
          this.weekMap.push({key:53-week+1,value:(53 - week+1)+"周("+month+"-8~"+month+"-14"+")"})
          this.weekMap.push({key:53-week+2,value:(53 - week+2)+"周("+month+"-15~"+month+"-21"+")"})
          this.weekMap.push({key:53-week+3,value:(53 - week+3)+"周("+month+"-22~"+month+"-28"+")"})
          this.weekMap.push({key:53-week+4,value:(53 - week+4)+"周("+month+"-29~"+(Number(month)+1)+"-4"+")"})
        }else if(week1==6){
          this.weekMap.push({key:53-week,value:(53 - week)+"周("+month+"-3~"+month+"-9"+")"})
          this.weekMap.push({key:53-week+1,value:(53 - week+1)+"周("+month+"-10~"+month+"-16"+")"})
          this.weekMap.push({key:53-week+2,value:(53 - week+2)+"周("+month+"-17~"+month+"-23"+")"})
          this.weekMap.push({key:53-week+3,value:(53 - week+3)+"周("+month+"-24~"+month+"-30"+")"})
          this.weekMap.push({key:53-week+4,value:(53 - week+4)+"周("+month+"-31~"+(Number(month)+1)+"-6"+")"})
        }else if(week1==0){
          this.weekMap.push({key:53-week,value:(53 - week)+"周("+month+"-2~"+month+"-8"+")"})
          this.weekMap.push({key:53-week+1,value:(53 - week+1)+"周("+month+"-9~"+month+"-15"+")"})
          this.weekMap.push({key:53-week+2,value:(53 - week+2)+"周("+month+"-16~"+month+"-22"+")"})
          this.weekMap.push({key:53-week+3,value:(53 - week+3)+"周("+month+"-23~"+month+"-29"+")"})
          this.weekMap.push({key:53-week+4,value:(53 - week+4)+"周("+month+"-30~"+(Number(month)+1)+"-5"+")"})
        }else{
          this.weekMap.push({key:53-week,value:(53 - week)+"周("+month+"-"+(9-week1)+"~"+month+"-"+(9-week1+6)+")"})
          this.weekMap.push({key:53-week+1,value:(53 - week + 1)+"周("+month+"-"+(9-week1+7)+"~"+month+"-"+(9-week1+13)+")"})
          this.weekMap.push({key:53-week+2,value:(53 - week + 2)+"周("+month+"-"+(9-week1+14)+"~"+month+"-"+(9-week1+20)+")"})
          this.weekMap.push({key:53-week+3,value:(53 - week + 3)+"周("+month+"-"+(9-week1+21)+"~"+((Number(month)+1)==13?1:(Number(month)+1))+"-"+(9-week1+27-31)+")"})
        }
      }else{
        if(week1==1){
          this.weekMap.push({key:53-week,value:(53 - week)+"周("+month+"-1~"+month+"-7"+")"})
          this.weekMap.push({key:53-week+1,value:(53 - week+1)+"周("+month+"-8~"+month+"-14"+")"})
          this.weekMap.push({key:53-week+2,value:(53 - week+2)+"周("+month+"-15~"+month+"-21"+")"})
          this.weekMap.push({key:53-week+3,value:(53 - week+3)+"周("+month+"-22~"+month+"-28"+")"})
          this.weekMap.push({key:53-week+4,value:(53 - week+4)+"周("+month+"-29~"+(month+1)+"-5"+")"})
        }else if(week1==6){
          this.weekMap.push({key:53-week,value:(53 - week)+"周("+month+"-3~"+month+"-9"+")"})
          this.weekMap.push({key:53-week+1,value:(53 - week+1)+"周("+month+"-10~"+month+"-16"+")"})
          this.weekMap.push({key:53-week+2,value:(53 - week+2)+"周("+month+"-17~"+month+"-23"+")"})
          this.weekMap.push({key:53-week+3,value:(53 - week+3)+"周("+month+"-24~"+month+"-30"+")"})
        }else if(week1==0){
          this.weekMap.push({key:53-week,value:(53 - week)+"周("+month+"-2~"+month+"-8"+")"})
          this.weekMap.push({key:53-week+1,value:(53 - week+1)+"周("+month+"-9~"+month+"-15"+")"})
          this.weekMap.push({key:53-week+2,value:(53 - week+2)+"周("+month+"-16~"+month+"-22"+")"})
          this.weekMap.push({key:53-week+3,value:(53 - week+3)+"周("+month+"-23~"+month+"-29"+")"})
          this.weekMap.push({key:53-week+3,value:(53 - week+4)+"周("+month+"-30~"+(Number(month)+1)+"-6"+")"})
        }else{
          this.weekMap.push({key:53-week,value:(53 - week)+"周("+month+"-"+(9-week1)+"~"+month+"-"+(9-week1+6)+")"})
          this.weekMap.push({key:53-week+1,value:(53 - week+1)+"周("+month+"-"+(9-week1+7)+"~"+month+"-"+(9-week1+13)+")"})
          this.weekMap.push({key:53-week+2,value:(53 - week+2)+"周("+month+"-"+(9-week1+14)+"~"+month+"-"+(9-week1+20)+")"})
          this.weekMap.push({key:53-week+3,value:(53 - week+3)+"周("+month+"-"+(9-week1+21)+"~"+(Number(month)+1)+"-"+(9-week1+27-30)+")"})
        }
      }
    },
    exportData() {
      this.$refs.modalForm.validate(valid => {
        if(valid) {
          let params = {};
          if (this.formDatas.userdepid) {
            params.userdepid = parseInt(this.formDatas.userdepid);
          }
          if (this.formDatas.year) {
            params.year = parseInt(this.formDatas.year);
          }
          if (this.formDatas.halfyear) {
            params.halfyear = parseInt(this.formDatas.halfyear);
          }
          if (this.formDatas.quarter) {
            params.quarter = parseInt(this.formDatas.quarter);
          }
          if (this.formDatas.month) {
            params.month = parseInt(this.formDatas.month);
          }
          if (this.formDatas.halfmonth) {
            params.halfmonth = parseInt(this.formDatas.halfmonth);
          }
          if (this.formDatas.week) {
            params.week = parseInt(this.formDatas.week);
            params.weekdetail = this.weekMap.filter(res=>{
              if(res.key==this.formDatas.week){
                return res.value
              }
            })
          }
          this.showLoading();
          exportPerformanceReport(params).then(() => {
            this.hideLoading();
          }).catch(() => {
            this.hideLoading();
          })
        }else {
          return false;
        }
      })
    }
  }
}
</script>